import { 
    Box,
    LinearProgress,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Tooltip,
    IconButton,
    Avatar,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import React, { useState, useEffect } from "react";

import FourOOne from '../general/FourOOne';

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const List = (props) => {

    const [status, setStatus] = useState("loading")
    const [users, setUsers] = useState()

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = () => {
        fetch(API + "/users/", 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => {            
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 401) {
                setStatus("401")                
                return null
            }
        })
        .then((data) => {
            if (data) {
                setUsers(data)
                setStatus("200")
            }
        })
        .catch((error) => console.log(error))
    }

    const requestChangeToken = (username) => {
        fetch(API + "/user/token/create/?username=" + username, 
                {
                method: 'POST',
                headers: AuthHeader()
                }
        )
        .then((response) => response.json())
        .then((data) => {
            if (data.status === "ok") loadUsers()
        })
        .catch((error) => console.log(error))

        
    }

    return (
        <>
           { status === "loading" 
            ?
                <Box sx={{ width: '100%', marginTop: 5 }}>
                    <LinearProgress color="secondary" />
                </Box> 
            : <></> }
            { status === "401" 
            ?
                <FourOOne/>
            : <></> }
            { status === "200" 
            ?
                    <TableContainer sx={{ flex: 1 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                <TableCell align='center' sx={{width: 10}}></TableCell>
                                <TableCell align='left' sx={{width: 200}}>Nombre</TableCell>
                                <TableCell align='center' sx={{width: 200}}>Nombre usuarie</TableCell>
                                <TableCell align='center' sx={{width: 50}}>Role</TableCell>
                                <TableCell align='center' sx={{width: 300}}>Token</TableCell>
                                <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    users.map((user, index) => {
                                        return (
                                            props.search_query === "" || user.name.toLowerCase().includes(props.search_query.toLowerCase()) || user.username.toLowerCase().includes(props.search_query.toLowerCase()) ?
                                            <TableRow hover={true} key={index}>
                                                <TableCell align='right'>
                                                    <Avatar alt={user.name} src={"/images/avatar/" + user.username + ".jpg"} />
                                                </TableCell>
                                                <TableCell align='left'>{user.name}</TableCell>
                                                <TableCell align='center'>{user.username}</TableCell>
                                                <TableCell align='center'>{user.role}</TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        user.change_token === "" ?
                                                            <Tooltip title="Generar token">
                                                                <IconButton onClick={() => {requestChangeToken(user.username)}}>
                                                                    <KeyIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        :
                                                            user.change_token
                                                    }
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Tooltip title="Abrir histórico">
                                                        <IconButton onClick={() => {window.open("/perfil/" + user.username, '_blank').focus()}}>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            : <></>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
            : <></>}    
        </>   
    )
}

export default List