import {
    Box,
    Button,
    LinearProgress,
    Chip,
    Tooltip,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from "react";

import AlertBox from './AlertBox';
  
import AuthHeader from '../../resources/AuthHeader';
var first_alert_id = ""
const API = process.env.REACT_APP_API_URL
const ALERTS_PER_PAGE = 6

let scroll_position = 0
let scrolled = false

const Alerts = (props) => {

    const [alerts, setAlerts] = useState({loading: true})
    const [show_archived, setShowArchived] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        const validate = (id) => {
            var body = {
                "archived": true
            }
            var headers = AuthHeader()
            headers.append("Content-Type", "application/json");
            fetch(API + "/alerts/" + id, 
                {
                    method: 'PATCH',
                    headers: headers,
                    body: JSON.stringify(body)
                }
            )
            .then((response) => response.json())
            .then((data) => {
                setAlerts({loading: true})
                loadAlerts()
            })
            .catch((error) => console.log(error))
        }

        const handleShortcuts = (event) => {
            if (first_alert_id !== "") {
                if (event.ctrlKey && event.code === "KeyQ") {
                    event.preventDefault();
                    window.close();
                } else if (event.ctrlKey && event.code === "KeyA") {
                    event.preventDefault();
                    validate(first_alert_id)
                }
            }
        }

        window.addEventListener("keydown", handleShortcuts)

        return () => {
            window.removeEventListener("keydown", handleShortcuts);
        }
    }, [])

    useEffect(() => {
        loadAlerts()
    }, [show_archived])

    const loadAlerts = () => {
        fetch(API + "/alerts/?resource_id=" + props.test_id + "&archived=" + show_archived, 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => response.json())
        .then((data) => {
            setAlerts(data)
            first_alert_id = data[0]["_id"]
        })
        .catch((error) => console.log(error))
    }

    useEffect(() => {
        if (!scrolled) {
            setTimeout(() => {
                document.getElementById('alerts_container').scrollTo(scroll_position,0)
                scrolled = true
            }, 1000)
        }
    }, [page])

    const filterActiveColor = (active) => {
        if (active)
            return getComputedStyle(document.documentElement).getPropertyValue("--actions-row-selected-filter");
        else
            return ""
    }

    return (
        <Box className="alert_page_container">
            {
                alerts.loading
                ?
                    <Box sx={{ width: '100%', marginTop: 5 }}>
                        <LinearProgress color="secondary" />
                    </Box> 
                :
                    <>
                        <Box className='actions_row'>
                            <Box className="actions_row_left_container">
                                <Tooltip title="Ver archivadas">
                                    <Chip
                                        label={"Archivadas"}
                                        variant="outlined"
                                        sx={{
                                            marginRight: 2,
                                            backgroundColor: filterActiveColor(show_archived)
                                        }}
                                        onClick={() => {
                                            setAlerts({loading: true})
                                            setShowArchived(!show_archived)
                                        }}
                                    />
                                </Tooltip>
                            </Box>
                            <Box className="actions_row_right_container">
                                <Tooltip title="Cerrar pestaña">
                                    <IconButton onClick={() => {window.close()}}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Editar test">
                                    <IconButton onClick={() => {window.location.href = "/test/" + props.test_id}}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>

                        {
                            alerts.length === 0 ?
                                <Box className="alerts_clear_text">
                                    No hay alertas pendientes
                                </Box>
                            :
                                <Box className="alerts_container" id="alerts_container">
                                    {
                                        alerts.slice(0,(page*(ALERTS_PER_PAGE-1))+1).map((alert, index) => {
                                            return (
                                                <AlertBox
                                                    reviewing={false}
                                                    allow_review={props.allow_review}
                                                    alert={alert}
                                                    users={props.users}
                                                    setAlertToReview={props.setAlertToReview}
                                                    onArchived={() => {
                                                        setAlerts({loading: true})
                                                        loadAlerts()
                                                    }}
                                                    key={index}
                                                />               
                                            )
                                        })
                                    }
                                    {
                                        alerts.length > (page*(ALERTS_PER_PAGE-1))+1 ?
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                style={{marginRight: 10}}
                                                onClick={() => {
                                                    scroll_position = document.getElementById('alerts_container').scrollTop;
                                                    scrolled = false
                                                    setPage(page+1)
                                                }
                                            }>
                                                Cargar más
                                            </Button>
                                        : <></>
                                    }
                                </Box>
                        }
                    </>
                
            }

    </Box>
    )
}

export default Alerts