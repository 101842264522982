import {
    Box,
    LinearProgress,
    Chip,
    Avatar,
    Tooltip,
    IconButton,
    Alert
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArchiveIcon from '@mui/icons-material/Archive';
import PreviewIcon from '@mui/icons-material/Preview';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import React, { useState, useEffect } from "react";

import ReactCompareImage from 'react-compare-image'

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const AlertBox = (props) => {

    const [alert, setAlert] = useState({loading: true})
    const [show_alert, setShowAlert] = useState(false)

    useEffect(() => {setAlert(props.alert)}, [props.alert])

    const validate = (id) => {
        var body = {
            "archived": true
        }
        var headers = AuthHeader()
        headers.append("Content-Type", "application/json");
        fetch(API + "/alerts/" + id, 
            {
                method: 'PATCH',
                headers: headers,
                body: JSON.stringify(body)
            }
        )
        .then((response) => response.json())
        .then((data) => {
            props.onArchived()
        })
        .catch((error) => console.log(error))
    }

    const getUserName = (username) => {
        if (username in props.users)
            return props.users[alert.reviewer]["name"]
        return username
    }

    return (
        <>
        {
            alert.loading ?
            <Box sx={{ width: '100%', marginTop: 5 }}>
                <LinearProgress color="secondary" />
            </Box> 
            :
            <Box className="test_alert">
                <Box className="test_alert_row">
                    <Box className="test_alert_header">
                        <Box className="test_alert_header_step_title">
                            Paso {alert.step}
                        </Box>
                        <Box className="test_alert_header_text">
                            Versión del driver: {alert.driver_version}
                        </Box>
                        <Box className="test_alert_archived_by">
                            {
                                alert.archived ? 
                                    <Chip
                                        avatar={<Avatar alt={getUserName(alert.reviewer)} src={"/images/avatar/" + alert.reviewer + ".jpg"} />}
                                        label={getUserName(alert.reviewer)}
                                        variant="outlined"
                                    />
                                : <></>
                            }
                        </Box>
                        
                    </Box>
                        
                    <Box className="test_alert_button">
                        {
                            !alert.archived && props.reviewing ? 
                                <Tooltip title="Volver"> 
                                    <IconButton onClick={() => {
                                        if (!alert.shared) {
                                            props.setAlertToReview("")
                                        }
                                    }}>
                                        <ArrowBackIcon/>
                                    </IconButton>
                                </Tooltip>
                            : <></>
                        }
                        <Tooltip title="Copiar vínculo para compartir"> 
                            <IconButton onClick={() => {
                                navigator.clipboard.writeText(window.location.protocol + "//" + window.location.hostname + "/alerta/" + alert._id);
                                setShowAlert(true)
                                setTimeout(() => setShowAlert(false), 5000)
                            }}>
                                <ContentCopyIcon/>
                            </IconButton>
                        </Tooltip>
                        {
                            !alert.archived ? 
                                <Tooltip title="Archivar alerta"> 
                                    <IconButton onClick={() => {
                                            validate(alert["_id"])
                                    }}>
                                        <ArchiveIcon/>
                                    </IconButton>
                                </Tooltip>
                            : <></>
                        }
                        {
                            !props.reviewing ? 
                                <Tooltip title="Revisar alerta"> 
                                    <IconButton onClick={() => {
                                        if (props.allow_review) {
                                            props.setAlertToReview(alert)
                                        }
                                    }}>
                                        <PreviewIcon/>
                                    </IconButton>
                                </Tooltip>
                            : <></>
                        }
                        {
                            !alert.archived && props.reviewing ? 
                                <Tooltip title="Reportar alerta para la actividad seleccionada"> 
                                    <IconButton onClick={() => {
                                        if (!alert.shared) {
                                            props.setOpenReport(true)
                                        }
                                    }}>
                                        <ReportProblemIcon/>
                                    </IconButton>
                                </Tooltip>
                            : <></>
                        }
                    </Box>
                </Box>
                {
                    alert.type === "screenshot" ? 
                        <ReactCompareImage 
                            leftImage={API + "/images/" + alert.value.old + "_old?no-cache-string=" + Math.random()}
                            rightImage={API + "/images/" + alert.value.new + "_new?no-cache-string=" + Math.random()}
                            leftImageLabel="Antigua"
                            rightImageLabel="Nueva"
                            sliderLineColor="#6D2077"
                        />
                    :
                        <Box>
                            <Box className="test_alert_text">
                                <span className="test_alert_text_key">Actualización:</span> {alert.type}
                            </Box>
                            <Box className="test_alert_text">
                                <span className="test_alert_text_key">Valor antiguo:</span> {alert.value.old}
                            </Box>
                            <Box className="test_alert_text">
                                <span className="test_alert_text_key">Valor nuevo:</span> {alert.value.new}
                            </Box>
                        </Box>
                }
            </Box>    
        }
        { show_alert ? 
          <Box className="alert_container">
            <Alert severity="info">
                Copiado al portapapeles
            </Alert>
          </Box>
      : <></> }
    </>
)
}

export default AlertBox