import { Box, Tooltip, Fab, TextField } from '@mui/material';
import React, { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';

import List from "../components/users/List"
import NewUser from '../components/users/NewUser';

import "./Users.css"

const Users = (props) => {

  const [search_query, setSearchQuery] = useState("")
  const [open_new_user_modal, setOpenNewUserModal] = useState(false)

  useEffect(() => {props.setScrumbs("Usuaries")}, [props])
  
  return (
    <Box className="users_page">

      <NewUser
        open={open_new_user_modal}
        close={setOpenNewUserModal}
      />

      <Box className='actions_row'>
        <Box className="actions_row_left_container">
          <Box className="search_box_container">
            <TextField
              fullWidth
              id="fullWidth"
              color="secondary"
              size="small"
              variant="standard"
              placeholder="Diodi"
              value={search_query}
              onChange={(event) => {
                setSearchQuery(event.target.value)
              }}
            />
          </Box>
        </Box>
        <Box className="actions_row_right_container">
          
        </Box>
      </Box>

      <List
        search_query={search_query}
      />

      <Box className="fab_container">
        <Tooltip title="Añadir nuevo test">
          <Fab
            color="secondary"
            aria-label="add"
            onClick={() => {
              setOpenNewUserModal(true)
          }}
          >
            <AddIcon/>
          </Fab>
        </Tooltip>        
      </Box>
    </Box>
  )
}

export default Users