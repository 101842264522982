import { TextField, Fab, Box, Tooltip, Alert  } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from "react";
import "./Activities.css"

import Editor from '../components/activities/Editor';
import Report from '../components/review/Report';
import List from '../components/activities/List';

const Activities = (props) => {

  const [search_query, setSearchQuery] = useState("")

  const [selected_activity, setSelectedActivity] = useState(null)
  const [open_activity_editor, setOpenActivityEditor] = useState(false)
  const [open_report, setOpenReport] =  useState(false)

  const [show_alert, setShowAlert] = useState(false)

  useEffect(() => {props.setScrumbs("Actividades")})

  return (
    <Box className="activities_page">

      <Editor
        open={open_activity_editor}
        close={setOpenActivityEditor}
        selected_activity={selected_activity}
      />

      {
        selected_activity !== null ?
          <Report
            open={open_report}
            setOpen={setOpenReport}
            alert={null}
            activity={selected_activity}
          />
        : <></>
      }
      

      <Box className='actions_row'>
        <Box className="actions_row_left_container">
            <Box className="search_box_container">
              <TextField
                fullWidth
                id="fullWidth"
                color="secondary"
                size="small"
                variant="standard"
                placeholder="Enigmas"
                onChange={(event) => {
                  setSearchQuery(event.target.value)
                }}
              />
            </Box>
          </Box>
        <Box className="actions_row_right_container">
          
        </Box>
      </Box>
      
      <List
        search_query={search_query}
        setSelectedActivity={setSelectedActivity}
        open_activity_editor={open_activity_editor}
        setOpenActivityEditor={setOpenActivityEditor}
        setOpenReport={setOpenReport}
        showAlert={setShowAlert}
      />

      <Box className="fab_container">
        <Tooltip title="Añadir nuevo test">
          <Fab
            color="secondary"
            aria-label="add"
            onClick={() => {
              setSelectedActivity(null)
              setOpenActivityEditor(true)
            }}
          >
            <AddIcon/>
          </Fab>
        </Tooltip>        
      </Box>

      { show_alert ? 
          <Box className="alert_container">
            <Alert severity="info">
                Copiado al portapapeles
            </Alert>
          </Box>
      : <></> }

    </Box>
  );
}
  
export default Activities;