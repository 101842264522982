import { Box, Alert, TextField } from '@mui/material';
import React, { useEffect, useState } from "react";
import List from "../components/variables/List"

import "./Variables.css"

const Variables = (props) => {

  const [search_query, setSearchQuery] = useState("")
  const [show_alert, setShowAlert] = useState(false)

  useEffect(() => {props.setScrumbs("Variables de sistema")}, [props])
  
  return (
    <Box className="variables_page">
      <Box className='actions_row'>
        <Box className="actions_row_left_container">
          <Box className="search_box_container">
            <TextField
              fullWidth
              id="fullWidth"
              color="secondary"
              size="small"
              variant="standard"
              placeholder="code"
              value={search_query}
              onChange={(event) => {
                setSearchQuery(event.target.value)
              }}
            />
          </Box>
        </Box>
        <Box className="actions_row_right_container">
          
        </Box>
      </Box>

      <List
        search_query={search_query}
        showAlert={setShowAlert}
      />

      { show_alert ? 
          <Box className="alert_container">
            <Alert severity="info">
                Copiado al portapapeles
            </Alert>
          </Box>
      : <></> }
    </Box>
  )
}

export default Variables