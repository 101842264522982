import { 
    Box,
    LinearProgress,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Tooltip,
    IconButton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useState, useEffect } from "react";

import FourOOne from '../general/FourOOne';

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const List = (props) => {

    const [status, setStatus] = useState("loading")
    const [variables, setVariables] = useState()

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = () => {
        fetch(API + "/variables/", 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => {            
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 401) {
                setStatus("401")                
                return null
            }
        })
        .then((data) => {
            if (data) {
                setVariables(data)
                setStatus("200")
            }
        })
    }

    return (
        <>
           { status === "loading" 
            ?
                <Box sx={{ width: '100%', marginTop: 5 }}>
                    <LinearProgress color="secondary" />
                </Box> 
            : <></> }
            { status === "401" 
            ?
                <FourOOne/>
            : <></> }
            { status === "200" 
            ?
                    <TableContainer sx={{ height: "100%" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                <TableCell align='left' sx={{width: 200}}>Nombre</TableCell>
                                <TableCell align='left' sx={{width: 600}}>Valor</TableCell>
                                <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    variables.map((variable, index) => {
                                        return (
                                            props.search_query === "" || variable.name.toLowerCase().includes(props.search_query.toLowerCase()) ?
                                                <TableRow hover={true} key={index}>
                                                    <TableCell align='left'>
                                                        <Tooltip title="Copiar nombre">
                                                            <IconButton onClick={() => {
                                                                navigator.clipboard.writeText(variable.name);
                                                                props.showAlert(true)
                                                                setTimeout(() => props.showAlert(false), 5000)
                                                            }}>
                                                                <ContentCopyIcon style={{ width: 20, marginLeft: 5 }}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        {variable.name}
                                                    </TableCell>
                                                    <TableCell align='left' style={{display: "flex", flexDirection: "row"}}>
                                                        <Tooltip title="Copiar valor">
                                                            <IconButton onClick={() => {
                                                                navigator.clipboard.writeText(variable.value);
                                                                props.showAlert(true)
                                                                setTimeout(() => props.showAlert(false), 5000)
                                                            }}>
                                                                <ContentCopyIcon style={{ width: 20, marginLeft: 5 }}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <p>{variable.value}</p>
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                    </TableCell>
                                                </TableRow>
                                            : <></>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
            : <></>}    
        </>   
    )
}

export default List