import { Chip, Stack, Tooltip } from '@mui/material';
import React, { useEffect, useState } from "react";
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import "./EngineAlert.css"

import getToken from '../../resources/getToken';
const API = process.env.REACT_APP_API_URL

const EngineAlert = (props) => {

  const [engine, setEngine] = useState(true)

  useEffect(() => {
    setEngine({
      name: props.engine_id
    })
    let eventSource = new EventSource(API + "/engines/" + props.engine_id + "/events?token=" + getToken());
    eventSource.onmessage = event => {
      setEngine(JSON.parse(event.data))
    };
    eventSource.onerror = error => {
      console.error('EventSource failed:', error);
    };
    return () => {
      eventSource.close();
      console.log("EventSource closed")
    };
  }, [props.engine_id])

  const changeEngine = () => {
    let engine_id = props.engine_id !== "engine_1" ? "engine_1" : "engine_" + props.user.username
    props.setEngineId(engine_id)
    var date = new Date();
    date.setTime(date.getTime() + (365*24*60*60*1000));
    document.cookie = "engine_id=" + engine_id + "; expires=" + date.toUTCString();
  }

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Cambiar engine">
        <Chip
          icon={((Date.now()/1000) - engine.heartbeat) < 5 ? <CloudQueueIcon/> : <CloudOffIcon/> }
          label={engine.name}
          variant="outlined"
          onClick={() => {
            changeEngine()
          }}
        />
      </Tooltip>
    </Stack>
  )
}

export default EngineAlert