import './App.css';
import { Button, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Tests from './pages/Tests'
import Activities from './pages/Activities';
import Alert from './pages/Alert';
import Test from './pages/Test';
import Login from './pages/Login';
import Password from './pages/Password';
import User from './pages/User';
import History from './pages/History';
import Review from './pages/Review';
import Variables from './pages/Variables';
import Users from './pages/Users';

import Avatar from '@mui/material/Avatar';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
 
import AuthHeader from './resources/AuthHeader';
const API = process.env.REACT_APP_API_URL
const SCRUMBS_MAX_LENGTH = 35

const spreadsheet_url = "https://docs.google.com/spreadsheets/d/1zCzVb6UaU8Atf-t1zSKCedEJdsmkpilPy8DHiOYoQLo/edit#gid=1260086339"

const mui_dark_theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#B68FBB",
    },
    secondary: {
      main: "#6D2077",
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
  },
});

const mui_light_theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#8A4D92",
    },
    secondary: {
      main: "#6D2077",
    },
  },
});

function App() {

  const [user, setUser] = useState({loading: true})
  const [scrumbs, setScrumbs] = useState("")
  const [dark_theme, setDarkTheme] = useState(false)

  useEffect(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDarkTheme(true)
    }
  })

  useEffect(() =>{
    document.documentElement.setAttribute("data-theme", dark_theme ? "dark" : "light");
  }, [dark_theme])

  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = () => {
    fetch(API + "/users/me", 
          {
            method: 'GET',
            headers: AuthHeader()
          }
    )
    .then((response) => {
      if (response.status === 401 || response.status === 403)
        throw new Error(response.status)
      return response.json()
    })
    .then((data) => {
      setUser(data)
    })
    .catch((error) => {
      if (error.message === "401" || error.message === "403") {
        if (window.location.href.split("/")[3] !== "login" && 
          window.location.href.split("/")[3] !== "password")
        window.location.href = "/login"
      } else {
        console.log(error)
      }
    })
  }

  const setScrumbsText = (text) => {
    let _scrumbs = text.length < SCRUMBS_MAX_LENGTH ? text : text.substring(0,SCRUMBS_MAX_LENGTH+1) + "..."
    setScrumbs(_scrumbs)
  }

  return (
    <Router>
      <ThemeProvider theme={(dark_theme ? mui_dark_theme : mui_light_theme)}>
        <div className="App">
          {
            user.loading
            ? 
              <></>
            :
              <header className='header'>
                <div className="header_left_container">
                  <img src="/icon.png" className='header_icon' alt="icono"/>
                  <span className='header_title'>Atlas</span>
                  <span className='header_crumbs'>{scrumbs}</span>
                </div>
                <div className="header_right_container">
                  <Tooltip title="Mi perfil">
                    <Link to="/perfil/yo" style={{textDecoration: "none"}}>
                      <Avatar alt={user.name} src={"/images/avatar/" + user.username + ".jpg"} className="header_avatar"/>
                    </Link>
                  </Tooltip>
                  {
                    user.role === "tester" || user.role === "admin" ?
                      <>

                        <Button variant="text" color="primary" onClick={() => window.open(spreadsheet_url, '_blank').focus()}>Procesos <OpenInNewIcon style={{ width: 15, marginLeft: 5 }}/></Button>
                        <Link to="/activities" style={{ textDecoration: 'none' }}><Button variant="text" color="primary">Actividades</Button></Link>
                        <Link to="/variables" style={{ textDecoration: 'none' }}><Button variant="text" color="primary">Variables</Button></Link>
                        <Link to="/tests" style={{ textDecoration: 'none' }}><Button variant="text" color="primary">Tests</Button></Link>
                      </>
                    : <></>
                  }
                  
                  {
                    user.role === "admin" ?
                      <Link to="/usuaries" style={{ textDecoration: 'none' }}><Button variant="text" color="primary">Usuaries</Button></Link>
                    : <></>
                  }
                  
                </div>
              </header>
          }
          
          <>
            <Routes>
              <Route exact path="/login/"  element={<Login/>}/>
              <Route exact path="/password/" element={<Password/>}/>            
              {
                user.loading ? <></> :
                <>
                  <Route exact path="/" element={<Tests setScrumbs={setScrumbsText} user={user}/>}/>                
                  <Route path="/tests" element={<Tests setScrumbs={setScrumbsText} user={user}/>}/>                
                  <Route path="/test/:test_id" element={<Test setScrumbs={setScrumbsText} />}/>                
                  <Route path="/history/:test_id" element={<History setScrumbs={setScrumbsText}/>}/>                
                  <Route path="/review/:test_id" element={<Review setScrumbs={setScrumbsText} user={user}/>}/>                
                  <Route path="/alerta/:alert_id" element={<Alert setScrumbs={setScrumbsText} user={user}/>}/>                
                  <Route path="/activities" element={<Activities setScrumbs={setScrumbsText}/>}/>                
                  <Route path="/variables" element={<Variables setScrumbs={setScrumbsText}/>}/>                
                  <Route path="/usuaries" element={<Users setScrumbs={setScrumbsText}/>}/>                
                  <Route path="/perfil/:username" element={<User setScrumbs={setScrumbsText}/>}/>                
                </>
              }
            </Routes>
          </>   
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
