import {
  Button,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  Box
} from '@mui/material';
import React, { useState } from "react";

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Report = (props) => {

  const [report_comment, setReportComment] = useState("");
  const [report_text, setReportText] = useState(false);
  const [report_apps, setReportApps] = useState(false);
  const [report_screenshots, setReportScreenshots] = useState(false);
  const [report_ilustrations, setReportIlustrations] = useState(false);
  const [report_downloads, setReportDownloads] = useState(false);
  const [report_studio, setReportStudio] = useState(false);
  const [report_video, setReportVideo] = useState(false);
  const [report_priority, setReportPriority] = useState("MINOR");

  const [upload_image_enabled, setUploadImageEnabled] = useState(true)

  const [show_alert, setShowAlert] = useState(false)
  const [alert_code, setAlertCode] = useState("")

  const reportApp = () => {
    const activity_id = props.activity._id
    // Get date
    var date = new Date()
    const register_date = date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear()
    // Process the code
    const type = activity_id.includes("I") ? "Premium" : "Open"
    var itinerary_code = ""
    if (activity_id.includes("I")) {
      itinerary_code = activity_id.split("A")[0].split("I")[1]
    } else {
      itinerary_code = activity_id.split("A")[0]
    }
    var activity_code = activity_id.split("A")[1]
    // Get the activity
    const activity = props.activity
    const activity_str = "=HIPERVINCULO(\"" + activity["web"] + "\";\"" + activity["name"] + "\")"
    // Compose line
    var line = [
      register_date,
      type,
      itinerary_code,
      activity_code,
      activity_str,
      "CAMBIO APP",
      "",
      report_comment,
      report_text ? "Si" : "No",
      report_apps ? "Si" : "No",
      report_screenshots ? "Si" : "No",
      report_ilustrations ? "Si" : "No",
      report_downloads ? "Si" : "No",
      report_studio ? "Si" : "No",
      report_video ? "Si" : "No",
      "",
      report_priority,
      "Pending approval"
    ]

    var report = {
      "id": activity_id,
      "report": line
    }
    var headers = AuthHeader()
    headers.append("Content-Type", "application/json");

    fetch(API + "/processes", 
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(report),
            redirect: 'follow'
          }
    )
    .then(response => response.json())
    .then(result => {
      setAlertCode(result.status === "ok" ? "ok" : "fail")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    })
    .catch(error => console.log('error', error));
  }

  const shareAlert = () => {
    setReportComment(report_comment + " " + window.location.protocol + "//" + window.location.hostname + "/alerta/" + props.alert._id)
    setUploadImageEnabled(false)
  }

  return (
    <Modal
      open={props.open}
      onClose={() => {props.setOpen(false)}}
    >
      <Box className='report_modal_container'>
        <Box className="report_modal_form">
          <Box className="report_modal_title">
            <h2>Reportar actividad - {props.activity["name"]}</h2>
          </Box>
          <FormGroup>
              <h3 className="form_title"><b>Descripción</b></h3>
              <TextField
                id="outlined-multiline-flexible"
                label="Comentario"
                variant="outlined"
                color="primary"
                multiline
                value={report_comment}
                onChange={(event) => {setReportComment(event.target.value)}}
              />
              <h3 className="form_title"><b>Cambios</b></h3>
              <Box>
                <FormControlLabel control={<Switch color="primary" checked={report_text} onChange={(event) => {setReportText(event.target.checked)}} />} label="Texto" />
                <FormControlLabel control={<Switch color="primary" checked={report_apps} onChange={(event) => {setReportApps(event.target.checked)}} />} label="Apps" />
                <FormControlLabel control={<Switch color="primary" checked={report_screenshots} onChange={(event) => {setReportScreenshots(event.target.checked)}} />} label="Capturas" />
                <FormControlLabel control={<Switch color="primary" checked={report_ilustrations} onChange={(event) => {setReportIlustrations(event.target.checked)}} />} label="Ilustraciones" />
                <FormControlLabel control={<Switch color="primary" checked={report_downloads} onChange={(event) => {setReportDownloads(event.target.checked)}} />} label="Descargables" />
                <FormControlLabel control={<Switch color="primary" checked={report_studio} onChange={(event) => {setReportStudio(event.target.checked)}} />} label="Sesión de estudio" />
                <FormControlLabel control={<Switch color="primary" checked={report_video} onChange={(event) => {setReportVideo(event.target.checked)}} />} label="Vídeo" />
              </Box>
              <h3 className="form_title"><b>Prioridad</b></h3>
              <ToggleButtonGroup
                value={report_priority}
                exclusive
                onChange={(event, priority) => {
                  if (priority === null) setReportPriority("MINOR");
                  else setReportPriority(priority);
                }}
              >
                <ToggleButton value="MINOR">
                  Minor
                </ToggleButton>
                <ToggleButton value="MAJOR">
                  Major
                </ToggleButton>
                <ToggleButton value="CRITICAL">
                  Critical
                </ToggleButton>
              </ToggleButtonGroup>

          </FormGroup>

          <Box className="report_button_container">                           
            <Button 
              color="primary"
              variant="outlined"
              style={{marginRight: 10}}
              disabled={!upload_image_enabled || props.alert === null}
              onClick={() => shareAlert()}
            >
              Añadir captura
            </Button>
            <Button color="secondary" variant="contained" onClick={() => reportApp()}>Reportar actividad</Button>
          </Box>
        </Box>
        <Box className="report_explanation">
        <Box className="report_modal_title">
            <h2>Niveles de prioridad</h2>
          </Box>
          <p>
            MINOR:	La actividad se puede hacer sin problemas aunque ha habido cambios en la app que utiliza (cambios estéticos, iconos, estructura, etc.).
          </p>
          <p>
            MAJOR:	Puede ser difícil intuir con los cambios en la App cómo seguir los pasos para poder hacer la actividad o llega feedback.
          </p>
          <p>
            CRITICAL:	Ocultada en CMS. Imposible realizarla.
          </p>        
        </Box>

        { show_alert ? 
            <Box className="alert_container">
              <Alert severity={alert_code === "ok" ? "success" : "error"}>
                {alert_code === "ok" ? "Reportado" : "Algo ha ido mal"}
              </Alert>
            </Box>
          : <></> }
      </Box>
    </Modal>
  )
}

export default Report