import {
    Modal,
    Box,
    TextField,
    Button,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem
  } from '@mui/material';
  
  import React, { useState } from "react";
  
  import AuthHeader from '../../resources/AuthHeader';
  const API = process.env.REACT_APP_API_URL
  
  const NewUser = (props) => {
  
    const [user, setUser] = useState({
      "username": "",
      "name": "",
      "role": "viewer"  
    })
    const [show_alert, setShowAlert] = useState(false)
    const [alert_code, setAlertCode] = useState("")
  
    const saveUser = () => {
  
      if (!checkSafeToSave()) {
        setAlertCode("fail")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000)
      } else {
        var body = user
        var headers = AuthHeader()
        headers.append("Content-Type", "application/json");    
        fetch(API + "/users", 
              {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body),
                redirect: 'follow'
              }
        )
        .then(response => response.json())
        .then(result => {
          setAlertCode(result.status === "ok" ? "ok" : "fail")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)
          if (result.status === "ok") {
            props.close(false)
          }
        })
        .catch(error => console.log('error', error));
      }
    }
  
    const checkSafeToSave = () => {
      let safe = true
      safe = safe && user.username.length > 0
      safe = safe && user.name.length > 0
      safe = safe && user.role.length > 0
      return safe
    }
    
    return (
      <Modal
          open={props.open}
          onClose={() => {props.close(false)}}
        >
          <Box className='new_user_modal_container'>
            <Box className="new_user_title">
              <h2>Nueve usuarie</h2>
            </Box>
            <Box className="new_user_form">
              <Box className="new_user_form_row">
                <Box className="new_user_form_input">
                  <TextField
                    id="name"
                    label="Nombre de usuarie"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    value={user.username}
                    onChange={event => {
                        let new_user = {...user}
                        new_user.username = event.target.value
                        setUser(new_user)
                    }}
                  />
                </Box>
              </Box>
              <Box className="new_user_form_row">
                <Box className="new_user_form_input">
                  <TextField
                    id="name"
                    label="Nombre"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    value={user.name}
                    onChange={event => {
                        let new_user = {...user}
                        new_user.name = event.target.value
                        setUser(new_user)
                    }}
                  />
                </Box>
              </Box>
              <Box className="new_user_form_row">
                <Box className="new_user_form_input">
                    <FormControl fullWidth>
                        <InputLabel id="campaign" color="primary">Role</InputLabel>
                        <Select
                            labelId = "role"
                            id = "role"
                            variant="outlined"
                            color="primary"
                            value = {user.role}
                            label = "role"
                            onChange = {event => {
                                let new_user = {...user}
                                new_user.role = event.target.value
                                setUser(new_user)
                            }}
                        >
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="viewer">Viewer</MenuItem>
                            <MenuItem value="tester">Tester</MenuItem>
                        </Select>
                    </FormControl>   
                </Box>
              </Box>
                        
            </Box>
            <Box className="new_user_buttons_row">
              <Button variant="contained" color="secondary" onClick={() => {saveUser()}}>Crear</Button>
            </Box>
            { show_alert ? 
              <Box className="alert_container">
                <Alert severity={alert_code === "ok" ? "success" : "error"}>
                  {alert_code === "ok" ? "Guardado" : "Algo ha ido mal"}
                </Alert>
              </Box>
            : <></> }
          </Box>
      </Modal>
    )
  }
  
  export default NewUser