import { Box } from '@mui/material';
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';

import List from '../components/history/List';

import "./History.css"

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const History = (props) => {

  const { test_id } = useParams()

  useEffect(() => {
    fetch(API + "/tests/" + test_id, 
          {
            method: 'GET',
            headers: AuthHeader()
          }
    )
    .then((response) => {            
      if (response.status === 200) {
          return response.json()
      } else if (response.status === 401) {  
          return null
      }
    })
    .then((data) => {
        if (data) {
          props.setScrumbs("Historia > " + data.name)
        } else {
          props.setScrumbs("Historia")
        }
        
    })
  }, [test_id, props])

  return (
    <Box className="history_page">

<Box className='actions_row'>
        <Box className="actions_row_left_container">
          <Box className="search_box_container">
            {/* <TextField
              fullWidth
              id="fullWidth"
              color="secondary"
              size="small"
              variant="standard"
              placeholder="seesaw /alerts>0/"
              value={search_query}
              onChange={(event) => {
                setSearchQuery(event.target.value)
                //(event.target.value)
              }}
            /> */}
          </Box>
        </Box>
        <Box className="actions_row_right_container">
          
        </Box>
      </Box>
      
      <List test_id={test_id} />
    </Box>
  )
}

export default History