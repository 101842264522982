import { 
    Box,
    LinearProgress,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TableContainer,
    Tooltip
} from '@mui/material';
import React, {useState, useEffect} from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Linkify from 'react-linkify';

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Procesess = (props) => {

    const [processes, setProcesses] = useState({loading: true});

    useEffect(() => {
        fetch(API + "/processes", 
            {
            method: 'GET',
            headers: AuthHeader()
            }
        )
        .then((response) => response.json())
        .then((data) => {
            let _itinerary_code = ""
            if (props.activity_id.includes("I")) {
                _itinerary_code = props.activity_id.split("A")[0].split("I")[1]
            } else {
                _itinerary_code = props.activity_id.split("A")[0]
            }
            let _activity_code = props.activity_id.split("A")[1] 
            let _processes = []
            for (let i = 0; i < data.length; i++) {
                let process = data[i]
                if (process["It"].toString() === _itinerary_code && process["Nº"].toString() === _activity_code) {
                    if (process["Estado"] !== "Resolved" && process["Estado"] !== "Duplicated") {
                        process["spreadsheet_index"] = i
                        _processes.push(process)
                    }
                }
            }
            setProcesses(_processes)
        })
        .catch((error) => console.log(error))
    }, [props.activity_id, props.open_report])       

  return (
    <>
    {
        processes.loading
        ?
            <Box sx={{ width: '80%', marginLeft: "10%", marginTop: 5 }}>
                <LinearProgress color="secondary" />
            </Box> 
        :
            <Box className="process_container">
                {
                    processes.length === 0
                    ?
                        <Box className="processes_clear_cotainer">
                            <h2> No hay reportes abiertos </h2>
                        </Box>
                    :
                        <TableContainer sx={{ flex: 1 }}>
                            <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                <TableCell align='center' sx={{width: 50}}>Fecha</TableCell>
                                <TableCell align='center' sx={{width: 120}}>Estado</TableCell>
                                <TableCell align='center' sx={{width: 60}}>Urgencia</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell sx={{width: 80}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                processes.map((process, index) => {
                                    return (
                                        <TableRow hover={true} height={75} key={index}>
                                            <TableCell align='center'>
                                                {process["Fecha entrada"]}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {process["Estado"]}                       
                                            </TableCell>
                                            <TableCell align='center'>
                                                {process["Urgencia"]}                     
                                            </TableCell>
                                            <TableCell>
                                                <Linkify>{process["Comentarios cambios Apps"]}</Linkify>           
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Copiar enlace de actividad"> 
                                                    <IconButton onClick={() => {
                                                        navigator.clipboard.writeText("https://docs.google.com/spreadsheets/d/1zCzVb6UaU8Atf-t1zSKCedEJdsmkpilPy8DHiOYoQLo/edit#gid=1260086339&range=A" + (process.spreadsheet_index + 2));
                                                        props.showAlert(true)
                                                        setTimeout(() => props.showAlert(false), 5000)
                                                    }}>
                                                        <ContentCopyIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Abrir actividad"> 
                                                    <IconButton onClick={() => {window.open("https://docs.google.com/spreadsheets/d/1zCzVb6UaU8Atf-t1zSKCedEJdsmkpilPy8DHiOYoQLo/edit#gid=1260086339&range=A" + (process.spreadsheet_index + 2),'_blank');}}>
                                                        <OpenInNewIcon/>
                                                    </IconButton>
                                                </Tooltip>  
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    }
                
            </Box>
        }
    </> 
  )
}

export default Procesess