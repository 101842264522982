import { 
    Box,
    LinearProgress,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Chip,
    Avatar,
    Tooltip
} from '@mui/material';
import React, { useState, useEffect } from "react";

import Viewer from './Viewer';
import FourOOne from '../general/FourOOne';

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const List = (props) => {

    const [status, setStatus] = useState("loading")
    const [history, setHistory] = useState({loading: true})
    const [users, setUsers] = useState({})
    const [engines, setEngines] = useState({})
    const [viewer_value, setViewerValue] = useState(null)

    useEffect(() => {
        fetch(API + "/tests/" + props.test_id + "/history/", 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => {            
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 401) {
                setStatus("401")                
                return null
            }
        })
        .then((data) => {
            if (data) {
                setHistory(data)
                setStatus("200")
            }
        })
    }, [props.test_id])

    useEffect(() => {
        fetch(API + "/users/?format=dictionary", 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => response.json())
        .then((data) => {
            setUsers(data)
        })
    }, [])
    
    useEffect(() => {
        fetch(API + "/engines/?format=dictionary", 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => response.json())
        .then((data) => {
            setEngines(data)
        })
    }, [])

    const getActorName = (id) => {
        if (Object.keys(users).includes(id))
            return users[id]["name"]

        if (Object.keys(engines).includes(id))
            return engines[id]["name"]

        return id
    }

    const getStringOfValue = (value) => {
        const length_to_return = 90
        const text = JSON.stringify(value)
        if (text.length > length_to_return)
            return text.substring(0, length_to_return) + "..."
        return text
    }

    return (
        <>
            <Viewer value={viewer_value} setViewerValue={setViewerValue}/>

            { status === "loading" 
            ?
                <Box sx={{ width: '100%', marginTop: 5 }}>
                    <LinearProgress color="secondary" />
                </Box> 
            : <></> }
            { status === "401" 
            ?
                <FourOOne/>
            : <></> }
            { status === "200" 
            ?
                <TableContainer sx={{ flex: 1 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            <TableCell sx={{width: 60}}>Tipo</TableCell>
                            <TableCell sx={{width: 60}}>Clave</TableCell>
                            <TableCell sx={{width: 35}}>Exito</TableCell>
                            <TableCell sx={{width: 100}}>Agente</TableCell>
                            <TableCell sx={{width: 240}}>Tiempo</TableCell>
                            <TableCell >Valor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                history.map((register, index) => {
                                    return (
                                        <TableRow hover={true} key={index}>
                                            <TableCell>{register.type}</TableCell>
                                            <TableCell>{register.key}</TableCell>
                                            <TableCell>{register.success ? "Sí": "No"}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    avatar={<Avatar alt={getActorName(register.user)} src={"/images/avatar/" + register.user + ".jpg"} />}
                                                    label={getActorName(register.user)}
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                            <TableCell>{register.timestamp}</TableCell>
                                            <TableCell onClick={() => setViewerValue(register.value)}>
                                                <Tooltip title="Abrir el registro">
                                                    <Box className="history_value_container">
                                                        {getStringOfValue(register.value)}
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            : <></>}   
  
        </>   
    )
}

export default List